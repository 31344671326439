import React, { useContext, useState } from 'react'
import SEO from '~/components/seo'
import StoreContext from '~/context/StoreContext'
import { BrowserView, MobileView } from 'react-device-detect'
import {
  GeneralGrid,
  CostumerMenu,
  CostumerMenuItems,
  CostumerText,
  ContactField,
  ContactText,
  CostumerTextField,
  TermsText,
  TermsSubheadline,
  ContactLink,
  TermsWrapperMobile,
  TermsHeadline,
} from '~/utils/styles'
import Layout from '../layouts'
import Navigation from '../components/Navigation'

const IndexPage = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const [openClose, setOpenClose] = useState({
    contact: false,
    ship: false,
    terms: false,
    privacy: false,
  })

  return (
    <div>
      <SEO
        title="BDST | BY BEN TAVERNITI | Bureau de Stil"
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />

      <BrowserView>
        <Layout>
          <GeneralGrid>
            <Navigation />
            <CostumerMenu>
              <CostumerMenuItems
                value="contact"
                style={{ color: openClose.contact ? '#cccccc' : 'black' }}
                onClick={e =>
                  setOpenClose({
                    contact: true,
                    ship: false,
                    terms: false,
                    privacy: false,
                  })
                }
              >
                CONTACT
              </CostumerMenuItems>
              <CostumerMenuItems
                value="ship"
                style={{ color: openClose.ship ? '#cccccc' : 'black' }}
                onClick={e =>
                  setOpenClose({
                    contact: false,
                    ship: true,
                    terms: false,
                    privacy: false,
                  })
                }
              >
                SHIPPING & RETURNS
              </CostumerMenuItems>
              <CostumerMenuItems
                value="terms"
                style={{ color: openClose.terms ? '#cccccc' : 'black' }}
                onClick={e =>
                  setOpenClose({
                    contact: false,
                    ship: false,
                    terms: true,
                    privacy: false,
                  })
                }
              >
                TERMS OF USE
              </CostumerMenuItems>
              <CostumerMenuItems
                value="privacy"
                style={{ color: openClose.privacy ? '#cccccc' : 'black' }}
                onClick={e =>
                  setOpenClose({
                    contact: false,
                    ship: false,
                    terms: false,
                    privacy: true,
                  })
                }
              >
                PRIVACY POLICY
              </CostumerMenuItems>
            </CostumerMenu>
            {openClose.contact ? (
              <ContactField>
                <ContactLink href="mailto:b1@bureaudestil.com">
                  Email: b1@bureaudestil.com
                </ContactLink>
                <ContactLink href="tel:+1(844)2920190">
                  Phone: +1 (844) 292-0190
                </ContactLink>{' '}
              </ContactField>
            ) : null}

            {openClose.ship ? (
              <ContactField>
                <TermsSubheadline>Returns and exchange</TermsSubheadline>
                <TermsText>
                  If a customer is unsatisfied with their order for any reason
                  the item/s in question can be returned to Bureau de Still for
                  an exchange or refund within 10 days of delivery. We cannot
                  offer exchanges or returns of merchandise that require cites
                  documentation to travel. For more information on returns and
                  exchanges please refer to our return policy.
                </TermsText>

                <TermsSubheadline>
                  Shipping, insurance and delivery
                </TermsSubheadline>

                <TermsText>
                  Orders are shipped within 2 working days of received payment.
                  During sale seasons shipping time may be delayed due to a
                  large amount of orders being placed. Items are insured by the
                  carrier during delivery. Once the package is signed for at the
                  stated address by the client (or gift recipient) the insurance
                  is no longer valid and responsibility is transferred to the
                  customer. We ship with UPS or Fedex and require signature upon
                  receipt. If the client instructs the carrier contrary to this
                  requirement, the responsibility for the package’s security
                  lies solely with the client. More information regarding
                  shipping, insurance and delivery can be found on our shipping
                  and delivery policy.
                </TermsText>
              </ContactField>
            ) : null}

            {openClose.terms ? (
              <ContactField>
                <TermsSubheadline>Responsibility</TermsSubheadline>
                <TermsText>
                  This document contains the terms and conditions that govern
                  the website BureaudeStil.com. By using BureaudeStil.com the
                  user agrees to respect and act upon all the terms and
                  conditions in this document and all other policies on
                  BureaudeStil.com. Users who do not accept these terms may be
                  asked by BureaudeStil.com to refrain from using the site.
                  BureaudeStil.com reserves the right to change, remove or
                  replace terms, conditions and policies without notice or
                  warning. We urge users to check back often and stay up to date
                  on the latest updates and legal issues. The use and access of
                  BureaudeStil.com is for personal use only.
                </TermsText>
                <TermsSubheadline>Payment</TermsSubheadline>
                <TermsText>
                  We currently only accept payments using mastercard, visa,
                  american express and bank transfer. Payment details are not
                  saved unless requested by the customer and are done on an
                  encrypted, secure server. However, if information is accessed
                  by a third party not due to negligence on our part -
                  BureaudeStil.com will not be held responsible. For assistance
                  with payment methods please contact
                  customercare@BureaudeStil.com .
                </TermsText>
                <TermsSubheadline>Orders</TermsSubheadline>
                <TermsText>
                  Once an order has been placed the requested item/s are
                  automatically reserved until the credit card has been charged
                  or the bank transfer has been approved, at which point the
                  customer will receive an email notifying them the order has
                  been accepted with an official receipt of purchase. In cases
                  of advanced payment for items that will become available in
                  the future the card is charged when placing the order and the
                  item will be reserved until it is available and shipped unless
                  otherwise stated. In such cases bank transfers need to be
                  carried out within 2 days of placing the order or the product
                  will no longer be reserved. First use customers paying by
                  credit card might face a slight delay in charges due to an
                  authentication process that’s takes place. In such cases the
                  receipt and final confirmation of the order will be sent to
                  the customer after approval from the bank.
                </TermsText>

                <TermsSubheadline>Pricing and charges</TermsSubheadline>
                <TermsText>
                  Credit cards will be debited in USD, and thus, due to exchange
                  rates, the final price will be calculated in the applicable
                  exchange rate the day the credit card company processes the
                  transaction. Items shipped inside the usa, the eu union,
                  switzerland, hong kong, japan and south korea all include
                  taxes and duties within the stated product price. Customers
                  from other countries may be charged for taxes, duties and
                  import fees under their country’s law and will not be
                  reimbursed by BureaudeStil.com Shipping costs are country
                  specific and are calculated at checkout when selecting the
                  country to which to ship, different prices may occur. This is
                  as a result of either changes in currency or due to different
                  pricing in different countries. All orders are processed in
                  USD. At the beginning of each season, a fixed exchange rate is
                  determined, and is used to determine the estimated price in
                  the local currency. However, when the order is processed, the
                  exchange rate used is the current exchange rate. For this
                  reason, there may be a difference between the estimated cost,
                  and the cost charged at the time of processing. When a refund
                  is processed, the exchange rate used is that of the current
                  day. This may also cause a variance in the price payed and the
                  price refunded, due to fluctuating exchange rates.
                </TermsText>
                <TermsSubheadline>Sale</TermsSubheadline>
                <TermsText>
                  If a customer ordered an item and within 1 days of the placing
                  the order the specific item has been chosen to go on sale with
                  a reduced price the customer has the right to get the price
                  difference refunded by contacting
                  customercare@BureaudeStil.com . Sale items and items with
                  reduced prices follow the same regulation as full price items.
                </TermsText>

                <TermsSubheadline>Promotional codes</TermsSubheadline>
                <TermsText>
                  Promotional codes can be used during checkout only once unless
                  stated otherwise. The discount or bonus will only be visible
                  once the code has been entered into the promotional code box
                  on the checkout page and has been accepted. BureaudeStil.com
                  will not refund or be held responsible for promotional codes
                  that have not been used for any reason.
                </TermsText>

                <TermsSubheadline>
                  Intellectual property rights
                </TermsSubheadline>
                <TermsText>
                  All images, texts and designs presented on BureaudeStil.com
                  are copyrighted and trademarked by Bureau de Stil. Bureau de
                  Stil reserves the right to take legal action if any use of
                  these materials are used without direct consent from Bureau de
                  Stil.
                </TermsText>

                <TermsSubheadline>No waiver</TermsSubheadline>
                <TermsText>
                  If BureaudeStil.com chose not to take legal action against a
                  user following a breach of the terms and condition on their
                  behalf it does not mean legal action will not be taken if the
                  user commits another act that disregards the site’s terms,
                  conditions or policies, whether the breach is different or
                  not.
                </TermsText>

                <TermsSubheadline>Liability and compensation</TermsSubheadline>
                <TermsText>
                  BureaudeStil.com will not be held responsible for any harm,
                  damage or death caused by any service directly or indirectly
                  provided by BureaudeStil.com or that is caused directly or
                  indirectly by Bureau de Stil products, materials or personnel.
                </TermsText>

                <TermsSubheadline>Relationship with clients</TermsSubheadline>
                <TermsText>
                  By viewing, using and/or ordering any item/s or service from
                  BureaudeStil.com an official legal partnership is not formed
                  between Bureau de Stil and the customer. A delivery contract
                  is made and is dissolved once delivery is done.
                </TermsText>

                <TermsText>
                  BureaudeStil.com reserves the right to take legal action
                  against individuals that claim to be in a partnership with
                  Bureau de Stil based on services provided by BureaudeStil.com
                  .
                </TermsText>
                <TermsSubheadline>.</TermsSubheadline>
              </ContactField>
            ) : null}

            {openClose.privacy ? (
              <ContactField>
                <TermsSubheadline>Transparency</TermsSubheadline>
                <TermsText>
                  We want you to have the information you need to make
                  meaningful choices about your personal information. We
                  therefore commit to explaining in our privacy notices why we
                  collect your personal information and how that information is
                  used. We will strive to make our privacy notices easy to find,
                  access and understand.We want you to have the information you
                  need to make meaningful choices about your personal
                  information. We therefore commit to explaining in our privacy
                  notices why we collect your personal information and how that
                  information is used. We will strive to make our privacy
                  notices easy to find, access and understand.
                </TermsText>
                <TermsText>
                  We will take the appropriate steps to provide you with control
                  over the personal information we collect. We will never
                  require that you provide more personal information than is
                  necessary to buy our products or access our services.We will
                  take the appropriate steps to provide you with control over
                  the personal information we collect. We will never require
                  that you provide more personal information than is necessary
                  to buy our products or access our services.
                </TermsText>
                <TermsText>
                  We will take the appropriate steps to provide you with control
                  over the personal information we collect. We will never
                  require that you provide more personal information than is
                  necessary to buy our products or access our services.We will
                  take the appropriate steps to provide you with control over
                  the personal information we collect. We will never require
                  that you provide more personal information than is necessary
                  to buy our products or access our services.
                </TermsText>{' '}
                <TermsText>
                  If you do not want to receive email and physical marketing
                  communications from us, we will make it easy for you to
                  unsubscribe and will honor your request.
                </TermsText>
                <TermsSubheadline>Accountability</TermsSubheadline>
                <TermsText>
                  We hold ourselves accountable for our data practices and
                  living our Privacy Principles. Privacy and Data Security are
                  key components in the design, development and execution of any
                  project that deals with personal information. Our projects
                  that involve the use of your personal information are handled
                  by teams that are required to complete ongoing privacy,
                  security, and data ethics trainings.
                </TermsText>
                <TermsText>
                  We continuously strive to recognize areas where we have room
                  for improvement, to refine our processes, and to act as a
                  leader in data practices and privacy in our industry.
                </TermsText>
                <TermsSubheadline>Security</TermsSubheadline>
                <TermsText>
                  There is no Privacy without Data Security and we take our
                  obligations to safeguard personal information seriously.There
                  is no Privacy without Data Security and we take our
                  obligations to safeguard personal information seriously.
                </TermsText>
                <TermsText>
                  We have implemented rigorous policies, practices and
                  procedures that are designed to: limit access to personal
                  information as much as possible, protect personal information
                  against loss and unauthorized access, use, and disclosure, and
                  require appropriate security standards for external vendors
                  that touch personal information. We do not store credit card
                  information on our systems.
                </TermsText>
              </ContactField>
            ) : null}
          </GeneralGrid>
        </Layout>
      </BrowserView>

      <MobileView>
        <Navigation />
        <TermsWrapperMobile>
          <TermsSubheadline>Responsibility</TermsSubheadline>
          <TermsText>
            This document contains the terms and conditions that govern the
            website BureaudeStil.com. By using BureaudeStil.com the user agrees
            to respect and act upon all the terms and conditions in this
            document and all other policies on BureaudeStil.com. Users who do
            not accept these terms may be asked by BureaudeStil.com to refrain
            from using the site. BureaudeStil.com reserves the right to change,
            remove or replace terms, conditions and policies without notice or
            warning. We urge users to check back often and stay up to date on
            the latest updates and legal issues. The use and access of
            BureaudeStil.com is for personal use only.
          </TermsText>
          <TermsSubheadline>Payment</TermsSubheadline>
          <TermsText>
            We currently only accept payments using mastercard, visa, american
            express and bank transfer. Payment details are not saved unless
            requested by the customer and are done on an encrypted, secure
            server. However, if information is accessed by a third party not due
            to negligence on our part - BureaudeStil.com will not be held
            responsible. For assistance with payment methods please contact
            customercare@BureaudeStil.com .
          </TermsText>
          <TermsSubheadline>Orders</TermsSubheadline>
          <TermsText>
            Once an order has been placed the requested item/s are automatically
            reserved until the credit card has been charged or the bank transfer
            has been approved, at which point the customer will receive an email
            notifying them the order has been accepted with an official receipt
            of purchase. In cases of advanced payment for items that will become
            available in the future the card is charged when placing the order
            and the item will be reserved until it is available and shipped
            unless otherwise stated. In such cases bank transfers need to be
            carried out within 2 days of placing the order or the product will
            no longer be reserved. First use customers paying by credit card
            might face a slight delay in charges due to an authentication
            process that’s takes place. In such cases the receipt and final
            confirmation of the order will be sent to the customer after
            approval from the bank.
          </TermsText>
          <TermsSubheadline>Pricing and charges</TermsSubheadline>
          <TermsText>
            Credit cards will be debited in USD, and thus, due to exchange
            rates, the final price will be calculated in the applicable exchange
            rate the day the credit card company processes the transaction.
            Items shipped inside the usa, the eu union, switzerland, hong kong,
            japan and south korea all include taxes and duties within the stated
            product price. Customers from other countries may be charged for
            taxes, duties and import fees under their country’s law and will not
            be reimbursed by BureaudeStil.com Shipping costs are country
            specific and are calculated at checkout when selecting the country
            to which to ship, different prices may occur. This is as a result of
            either changes in currency or due to different pricing in different
            countries. All orders are processed in USD. At the beginning of each
            season, a fixed exchange rate is determined, and is used to
            determine the estimated price in the local currency. However, when
            the order is processed, the exchange rate used is the current
            exchange rate. For this reason, there may be a difference between
            the estimated cost, and the cost charged at the time of processing.
            When a refund is processed, the exchange rate used is that of the
            current day. This may also cause a variance in the price payed and
            the price refunded, due to fluctuating exchange rates.
          </TermsText>
          <TermsSubheadline>Sale</TermsSubheadline>
          <TermsText>
            If a customer ordered an item and within 1 days of the placing the
            order the specific item has been chosen to go on sale with a reduced
            price the customer has the right to get the price difference
            refunded by contacting customercare@BureaudeStil.com . Sale items
            and items with reduced prices follow the same regulation as full
            price items.
          </TermsText>
          <TermsSubheadline>Returns and exchange</TermsSubheadline>
          <TermsText>
            If a customer is unsatisfied with their order for any reason the
            item/s in question can be returned to Bureau de Still for an
            exchange or refund within 10 days of delivery. We cannot offer
            exchanges or returns of merchandise that require cites documentation
            to travel. For more information on returns and exchanges please
            refer to our return policy.
          </TermsText>
          <TermsSubheadline>Shipping, insurance and delivery</TermsSubheadline>
          <TermsText>
            Orders are shipped within 2 working days of received payment. During
            sale seasons shipping time may be delayed due to a large amount of
            orders being placed. Items are insured by the carrier during
            delivery. Once the package is signed for at the stated address by
            the client (or gift recipient) the insurance is no longer valid and
            responsibility is transferred to the customer. We ship with UPS or
            Fedex and require signature upon receipt. If the client instructs
            the carrier contrary to this requirement, the responsibility for the
            package’s security lies solely with the client. More information
            regarding shipping, insurance and delivery can be found on our
            shipping and delivery policy.
          </TermsText>
          <TermsSubheadline>Promotional codes</TermsSubheadline>
          <TermsText>
            Promotional codes can be used during checkout only once unless
            stated otherwise. The discount or bonus will only be visible once
            the code has been entered into the promotional code box on the
            checkout page and has been accepted. BureaudeStil.com will not
            refund or be held responsible for promotional codes that have not
            been used for any reason.
          </TermsText>
          <TermsSubheadline>Intellectual property rights</TermsSubheadline>
          <TermsText>
            All images, texts and designs presented on BureaudeStil.com are
            copyrighted and trademarked by Bureau de Stil. Bureau de Stil
            reserves the right to take legal action if any use of these
            materials are used without direct consent from Bureau de Stil.
          </TermsText>
          <TermsSubheadline>No waiver</TermsSubheadline>
          <TermsText>
            If BureaudeStil.com chose not to take legal action against a user
            following a breach of the terms and condition on their behalf it
            does not mean legal action will not be taken if the user commits
            another act that disregards the site’s terms, conditions or
            policies, whether the breach is different or not.
          </TermsText>
          <TermsSubheadline>Liability and compensation</TermsSubheadline>
          <TermsText>
            BureaudeStil.com will not be held responsible for any harm, damage
            or death caused by any service directly or indirectly provided by
            BureaudeStil.com or that is caused directly or indirectly by Bureau
            de Stil products, materials or personnel.
          </TermsText>
          <TermsSubheadline>Relationship with clients</TermsSubheadline>
          <TermsText>
            By viewing, using and/or ordering any item/s or service from
            BureaudeStil.com an official legal partnership is not formed between
            Bureau de Stil and the customer. A delivery contract is made and is
            dissolved once delivery is done.
          </TermsText>
          <TermsText>
            BureaudeStil.com reserves the right to take legal action against
            individuals that claim to be in a partnership with Bureau de Stil
            based on services provided by BureaudeStil.com .
          </TermsText>
          <TermsSubheadline>Transparency</TermsSubheadline>
          <TermsText>
            We want you to have the information you need to make meaningful
            choices about your personal information. We therefore commit to
            explaining in our privacy notices why we collect your personal
            information and how that information is used. We will strive to make
            our privacy notices easy to find, access and understand.We want you
            to have the information you need to make meaningful choices about
            your personal information. We therefore commit to explaining in our
            privacy notices why we collect your personal information and how
            that information is used. We will strive to make our privacy notices
            easy to find, access and understand.
          </TermsText>
          <TermsText>
            We will take the appropriate steps to provide you with control over
            the personal information we collect. We will never require that you
            provide more personal information than is necessary to buy our
            products or access our services.We will take the appropriate steps
            to provide you with control over the personal information we
            collect. We will never require that you provide more personal
            information than is necessary to buy our products or access our
            services.
          </TermsText>
          <TermsText>
            We will take the appropriate steps to provide you with control over
            the personal information we collect. We will never require that you
            provide more personal information than is necessary to buy our
            products or access our services.We will take the appropriate steps
            to provide you with control over the personal information we
            collect. We will never require that you provide more personal
            information than is necessary to buy our products or access our
            services.
          </TermsText>{' '}
          <TermsText>
            If you do not want to receive email and physical marketing
            communications from us, we will make it easy for you to unsubscribe
            and will honor your request.
          </TermsText>
          <TermsSubheadline>Accountability</TermsSubheadline>
          <TermsText>
            We hold ourselves accountable for our data practices and living our
            Privacy Principles. Privacy and Data Security are key components in
            the design, development and execution of any project that deals with
            personal information. Our projects that involve the use of your
            personal information are handled by teams that are required to
            complete ongoing privacy, security, and data ethics trainings.
          </TermsText>
          <TermsText>
            We continuously strive to recognize areas where we have room for
            improvement, to refine our processes, and to act as a leader in data
            practices and privacy in our industry.
          </TermsText>
          <TermsSubheadline>Security</TermsSubheadline>
          <TermsText>
            There is no Privacy without Data Security and we take our
            obligations to safeguard personal information seriously.There is no
            Privacy without Data Security and we take our obligations to
            safeguard personal information seriously.
          </TermsText>
          <TermsText>
            We have implemented rigorous policies, practices and procedures that
            are designed to: limit access to personal information as much as
            possible, protect personal information against loss and unauthorized
            access, use, and disclosure, and require appropriate security
            standards for external vendors that touch personal information. We
            do not store credit card information on our systems.
          </TermsText>
          <div style={{ padding: '0 0 0 12px' }}>
            <ContactLink href="mailto:b1@bureaudestil.com">
              Email: b1@bureaudestil.com
            </ContactLink>
            <ContactLink href="tel:+1(844)2920190">
              Phone: +1 (844) 292-0190
            </ContactLink>{' '}
          </div>
          <div style={{ height: '10vh' }} />
        </TermsWrapperMobile>
      </MobileView>
    </div>
  )
}

export default IndexPage
